.card-footer {
  text-align: right !important;
}

.btn {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.wedge_justifyContentCenter {
  margin: auto !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}